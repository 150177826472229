import React, { useState } from 'react';
import axios from 'axios';
import {FormattedMessage} from "react-intl";
import {Button, Card, Form, Input} from "antd";
import './Register.css';
import login from "../test/login";

function Register() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [inviteCode, setInviteCode] = useState('');

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            alert('Passwords do not match!');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/v1/register`,
                { email, password, re_password: confirmPassword, invite_code: inviteCode });
            if (response.data.code === 200) {
                // alert('Registration successful!');
                //设置登录状态
                //跳转到登录页面
                window.location.href = '/login';
            }else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="register-container">
            <Card title={<FormattedMessage id="register" />}  className="card-style">
                <Form onFinish={handleSubmit}>
                    <Form.Item name="email" rules={[{ required: true, message: '请输入邮箱!' }]}>
                        <Input type="email" placeholder="请输入邮箱" onChange={e => setEmail(e.target.value)} required/>
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input type="password" placeholder="请输入密码" onChange={e => setPassword(e.target.value)} required/>
                    </Form.Item>
                    <Form.Item name="confirmPassword" rules={[{ required: true, message: '请确认密码!' }]}>
                        <Input type="password" placeholder="请输入确认密码" onChange={e => setConfirmPassword(e.target.value)} required/>
                    </Form.Item>
                    <Form.Item name="inviteCode" rules={[{ required: true, message: '请输入邀请码!' }]}>
                        <Input placeholder="请输邀请码" onChange={e => setInviteCode(e.target.value)} required/>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <FormattedMessage id="register"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default Register;