import React from 'react';
import GSidebar from "../../commons/GSidebar";
import Layout from "../../commons/Layout";
import { Tabs } from "antd";
import { useLocation } from 'react-router-dom';


import './codeGenarate.css';
import * as PropTypes from "prop-types";
import Needs from "./needs";
import Prd from "./prd";
import Func from "./func";
import Tables from "./tables";
import Prd1 from "./prd1";

function TabList(props) {
    return null;
}

TabList.propTypes = {children: PropTypes.node};

function CodeGenarate() {
    const { TabPane } = Tabs;
    const sidebar = <GSidebar />;
    const Mytabs = () => {
        // 使用useLocation钩子获取当前的location对象
        const location = useLocation();
        // 使用new URLSearchParams对象获取查询参数
        const params = new URLSearchParams(location.search);
        const tabKey = params.get('tabKey');
        return (
            <div>
                <Tabs defaultActiveKey={tabKey || "1"}>
                    <TabPane tab="需求设计" key="1">
                        <h2>需求设计</h2>
                        <Needs />
                    </TabPane>
                    <TabPane tab="产品文档" key="2">
                        {/*<h2>产品文档</h2>*/}
                       <Prd1 />
                    </TabPane>
                    <TabPane tab="功能列表" key="3">
                        <h2>功能列表</h2>
                        <Func />
                    </TabPane>
                    <TabPane tab="表结构" key="4">
                        <h2>表结构</h2>
                      <Tables />
                    </TabPane>
                    <TabPane tab="程序流程" key="5">
                        <h2>程序流程</h2>
                        <p>This is the content of Tab 3. You can put any component here.</p>
                    </TabPane>
                    <TabPane tab="程序代码" key="6">
                        <h2>程序代码</h2>
                        <p>This is the content of Tab 3. You can put any component here.</p>
                    </TabPane>
                </Tabs>
            </div>
        );
    };

    const  content = <Mytabs />;

    return (
        <Layout sidebar={sidebar} content={content}/>
    );
}

export default CodeGenarate;