import React from 'react';

const EmailVerificationFail = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <h1>Email Verification Failed</h1>
            <p>Sorry, we couldn't verify your email. Please check the email and try again.</p>
        </div>
    );
}

export default EmailVerificationFail;