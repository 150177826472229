import React, {useContext, useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Navbar.css';
import {FormattedMessage} from "react-intl";
import {AuthContext} from "../provider/AuthContext";
import {NavbarContext} from "../provider/NavbarContext";
import {Dropdown, Menu} from "antd";
import {CodeOutlined, FilePdfOutlined, HomeOutlined, InfoCircleOutlined, MailOutlined, ProfileOutlined} from "@ant-design/icons";

function Navbar({ setLocale }) {
    const [currentLanguage, setCurrentLanguage] = useState('en');
    const languages = [
        { key: 'en', value: 'en', text: 'English' },
        { key: 'zh', value: 'zh', text: '中文' },
        // 添加更多语言选项...
    ];
    const { isLogged, login, logout } = useContext(AuthContext);
    const { isShow } = useContext(NavbarContext);

    const handleLanguageChange = (event, { value }) => {
        setLocale(value);
        setCurrentLanguage(value);
    };
    if (!isShow) {
        return null;
    }


    return (
        <>
        <Menu mode="horizontal">
                <>
                    <Menu.Item key="home" icon={<HomeOutlined />}>
                        <Link to="/">Home</Link>
                    </Menu.Item>
                    <Menu.Item key="about" icon={<InfoCircleOutlined />}>
                        <Link to="/about">About</Link>
                    </Menu.Item>
                    <Menu.Item key="projects" icon={<CodeOutlined />}>
                        <Link to="/projects">Projects</Link>
                    </Menu.Item>
                    <Menu.Item key="resume" icon={<FilePdfOutlined />}>
                        <Link to="/resume">Resume</Link>
                    </Menu.Item>
                    <Menu.Item key="contact" icon={<MailOutlined />}>
                        <Link to="/contact">Contact</Link>
                    </Menu.Item>
                </>
            {isLogged ? (
                <>
                    {/*<Menu.Item style={{marginRight: '30px', fontSize: '18px', border: 'none'}}>*/}
                    {/*    <a style={{ marginRight: '30px', fontSize: '18px', border: 'none' }} onClick={logout}>登出</a>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="profile" icon={<ProfileOutlined />}>
                        <Link to="/profile">
                            <FormattedMessage id="profile" defaultMessage="Profile" />
                        </Link>
                    </Menu.Item>
                </>
            ) : (
                <>
                <Menu.Item style={{ marginRight: '30px', fontSize: '18px', border: 'none' }}>
                        <Link to="/login" style={{ color: 'black' }}>
                            <FormattedMessage id="login" defaultMessage="Login" />
                        </Link>
                    </Menu.Item>
                    <Menu.Item style={{ marginRight: '30px', fontSize: '18px', border: 'none' }}>
                        <Link to="/register" style={{ color: 'black' }}>
                            <FormattedMessage id="register" defaultMessage="Register" />
                        </Link>
                    </Menu.Item>
                </>
                )}

        </Menu>
            {/*<Dropdown*/}
            {/*    item*/}
            {/*    text='Language'*/}
            {/*    options={languages}*/}
            {/*    onChange={handleLanguageChange}*/}
            {/*    value={currentLanguage}*/}
            {/*    style={{ position: 'absolute', right: '0', fontSize: '18px', color: 'black' }}*/}
            {/*/>*/}
       </>
    );
}

export default Navbar;