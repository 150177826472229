import React, { useState } from 'react';
import './GSidebar.css';
import { Link } from "react-router-dom";
import {Avatar, Button, Menu, Typography} from 'antd';
import { HomeOutlined, InfoCircleOutlined,
  CodeOutlined, FilePdfOutlined, MailOutlined,
  MenuFoldOutlined, MenuUnfoldOutlined,UserOutlined } from '@ant-design/icons';

const GSidebar = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  var navItems = [
    { name: 'Home', icon: <HomeOutlined />, link:'/profile' },
    { name: 'About', icon: <InfoCircleOutlined /> },
    { name: 'Projects', icon: <CodeOutlined />, link:'/projects' },
    { name: 'Resume', icon: <FilePdfOutlined /> },
    { name: 'Contact', icon: <MailOutlined /> }
  ];

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
      <Menu
          mode="inline"
          className="sidebar"
          style={{ width: collapsed ? 80 : props.width }}
      >
        {/*<Menu.Item key="user" disabled style={{ display: 'flex', alignItems: 'center' }}>*/}
        {/*  <Avatar src="user-avatar-url" />*/}
        {/*</Menu.Item>*/}
        {navItems.map((item, index) => {
          return (
              <Menu.Item key={index} icon={item.icon}>
                <Link to={item.link}>
                  {collapsed ? null : item.name}
                </Link>
              </Menu.Item>
          );
        })}
        <Menu.Item key="toggle" style={{ marginTop: 'auto' }}>
          <Button type="primary" onClick={toggleCollapsed}>
            {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
          </Button>
        </Menu.Item>
      </Menu>
  );
};

export default GSidebar;