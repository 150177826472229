import React from "react";

function History({ Content }) {
  return (
    <section>
      <h2>文档历史</h2>
      {Content.map((item, index) => (
        <div key={index}>
          <p>版本: {item.version}</p>
          <p>日期: {item.date}</p>
          <p>作者: {item.author}</p>
          <p>摘要: {item.summary}</p>
        </div>
      ))}
    </section>
  );
}

export default History;