import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import GSidebar from "../../commons/GSidebar";
import Layout from "../../commons/Layout";
import {Button} from "antd";

function ProjectDetail() {
    const { id } = useParams();
    const [project, setProject] = useState(null);

    useEffect(() => {
        const fetchProjectDetail = async () => {
            const url = `${process.env.REACT_APP_API_HOST}/v1/project/detail`;
            try {
                const response = await axios.get(url, {
                    withCredentials: true,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    params: {
                        "project_id":id
                    }
                });
                if (response.data.code === 200) {
                    setProject(response.data.data);
                } else {
                    console.log('Failed to fetch project detail');
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchProjectDetail();
    }, []);

    const sidebar = <GSidebar />;
    const Content = () => {
        if (!project) {
            return <div>Loading...</div>;
        }

        return (
            <div>
                <h1>{project.name}</h1>
                <p>{project.description}</p>
                <img src={project.image_url} alt={project.name} />
              <p><Button type="primary" href={`/project/requirements/${project.id}`}>需求创建</Button></p>
            </div>
        );
    };
    const content = <Content />;

    return (
        <Layout sidebar={sidebar} content={content} />
    );
}

export default ProjectDetail;