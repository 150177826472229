import React from 'react';
import GStarInfoSection from "./home/GStarInfoSection";
function Home() {
  return (
    <div>
      <GStarInfoSection />
    </div>
  );
}

export default Home;