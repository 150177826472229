// Layout.js
import React, {useContext, useEffect} from 'react';
import './Layout.css';
import {NavbarContext} from "../provider/NavbarContext";
import UserHeader from "./UserNavbar";
function Layout({ sidebar, content }) {
    const { showNavbar,hideNavbar } = useContext(NavbarContext);
    useEffect(() => {
        hideNavbar();

        return () => {
            showNavbar();
        };
    }, []);
    return (
        <>
            <UserHeader/>
            <div className="layout">
                <div className="sidebar">
                    {sidebar}
                </div>
                <div className="main-content">
                    {content}
                </div>
            </div>
        </>
    );
}

export default Layout;