import React, { useState } from 'react';
import GSidebar from "../../commons/GSidebar";
import Layout from "../../commons/Layout";
import {Button, Card, Form, Input} from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from 'axios';
import './Project.css';

const createProject = async (name, description, imageUrl) => {
    const url = `${process.env.REACT_APP_API_HOST}/v1/project/create`;
    const data = {
        name: name,
        description: description,
        image_url: imageUrl
    };

    try {
        const response = await axios.post(url, data, {
            withCredentials: true, //请求头Authorization
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        if (response.data.code === 200) {
            console.log('project created successfully');
        } else {
            console.log('Failed to create project');
        }
    } catch (error) {
        console.error(error);
    }
};
function ProjectForm() {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [backgroundImage, setBackgroundImage] = useState('');

    const handleSubmit = (event) => {
        // event.preventDefault();
        // Handle form submission here
        console.log('project Name:', projectName);
        console.log('project Description:', projectDescription);
        console.log('Background Image:', backgroundImage);
        createProject(projectName, projectDescription, backgroundImage).then(r => console.log(r));
    };

    // const handleImageUpload = (event) => {
    //     setBackgroundImage(event.target.files[0]);
    // };

    const sidebar = <GSidebar className="sidebar" />;
    const formContent = (
        <div className="project-form-container">
            <Card title="创建项目" className="card-style">
                <Form onFinish={handleSubmit}>
                    <Form.Item name="projectName" rules={[{ required: true, message: '请输入项目名称!' }]}>
                        <Input placeholder="项目名" onChange={e => setProjectName(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="projectDescription" rules={[{ required: true, message: '请输入项目描述!' }]}>
                        <TextArea placeholder="项目描述" onChange={e => setProjectDescription(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="backgroundImage" rules={[{ required: true, message: '请上传背景图!' }]}>
                        <Input type="text"  onChange={e => setBackgroundImage(e.target.value)}  />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );

    return (
        <Layout sidebar={sidebar} content={formContent} />
    );
}

export default ProjectForm;