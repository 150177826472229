import React, {useContext, useEffect, useState} from 'react';
import {NavbarContext} from "../../provider/NavbarContext";
import GSidebar from "../../commons/GSidebar";
import './Profile.css';
import Layout from "../../commons/Layout";

const Profile = () => {
    // const [sidebarVisible, setSidebarVisible] = useState("");
    //
    // const toggleBtn = () => {
    //     console.log(sidebarVisible === "thin" );
    //    sidebarVisible === "thin" ? setSidebarVisible(""):setSidebarVisible("thin");
    //    console.log(sidebarVisible);
    // };
    const sidebar = <GSidebar />;
    const content = <Content/>;
    return (
        <Layout sidebar={sidebar} content={content} />
    );
};
const Content = () => {
    return (
        <>
            <h1>个人首页</h1>
            <p>姓名: 用户名</p>
            <p>个人简介: 这是个人简介</p>
        </>
    );
};



export default Profile;