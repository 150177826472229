import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import './prd.css';
import History from "./prd/history";
import TabPane from "antd/es/tabs/TabPane";
import Introduction from "./prd/introduction";
import MilestonesSchedule from "./prd/milestonesSchedule";
import DataModel from "./prd/dataModel";
import UserFlowsDesigns from "./prd/userFlowsDesigns";
import RequirementsOverview from "./prd/requirementsOverview";
import Objectives from "./prd/objectives";
import {Tabs} from "antd";

function Prd1() {
  const [prd1Content, setPrd1Content] = useState(null);

    const {id} = useParams();
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_HOST}/v1/chat/prd`;
    axios.get(url, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      params: {
        "project_id": id,
      }
    })
        .then(response => {
            //解析response.data.data的json字符串
          // const parsedData = JSON.parse(response.data.data);
            console.log(response.data.data);
          const parsedData = JSON.parse(response.data.data);
            console.log(parsedData);
          setPrd1Content(parsedData);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }, []);
  return (
      <div className="parent-element">
          {prd1Content ? (
              <>
                  <Tabs defaultActiveKey="2.1">
                      {/* 文档历史 */}
                      <TabPane tab="文档历史" key="2.1">
                          <History Content={prd1Content.documentHistory} />
                      </TabPane>
                      {/* 引言 */}
                      <TabPane tab="引言" key="2.2">
                            <Introduction Content={prd1Content.introduction} />
                      </TabPane>
                      {/* 目标 */}
                      <TabPane tab="目标" key="2.3">
                          <Objectives Content={prd1Content.objectives} />
                      </TabPane>
                      {/* 需求概览 */}
                      <TabPane tab="需求概览" key="2.4">
                          <RequirementsOverview Content={prd1Content.requirementsOverview} />
                        </TabPane>
                        {/* 用户流程和设计 */}
                        <TabPane tab="用户流程和设计" key="2.5">
                            <UserFlowsDesigns Content={prd1Content.userFlowsDesigns} />
                        </TabPane>
                        {/* 数据模型 */}
                        <TabPane tab="数据模型" key="2.6">
                            <DataModel Content={prd1Content.dataModel} />
                        </TabPane>
                        {/* 里程碑和计划 */}
                        <TabPane tab="里程碑和计划" key="2.7">
                           <MilestonesSchedule Content={prd1Content.milestonesSchedule} />
                        </TabPane>
                  </Tabs>
              </>
          ) : 'Loading...'}
      </div>
  );
}

export default Prd1;