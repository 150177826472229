import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {IntlProvider} from 'react-intl';
import Register from "./register/Register";
import Login from "./login/Login";
import Home from "./Home";
import './App.css';
import Navbar from "./commons/Navbar";
import en from './translations/en.json';
import zh from './translations/zh.json';
import {AuthProvider} from "./provider/AuthProvider";
import EmailVerificationFail from "./verify/fail";
import EmailVerificationSuccess from "./verify/success";
import Profile from "./users/profile/Profile";
import {NavbarProvider} from "./provider/NavbarProvider";
import Projects from "./users/project/Project";
import ProjectForm from "./users/project/ProjectCreate";
import ProjectDetail from "./users/project/ProjectDetail";
import CodeGenarate from "./users/codeGenarate/codeGenarate";

function App() {
    const [locale, setLocale] = useState(localStorage.getItem('locale') || window.navigator.language.slice(0, 2));

    useEffect(() => {
        localStorage.setItem('locale', locale);
    }, [locale]);

    const messages = locale === 'en' ? en : zh;

    return (
        <IntlProvider locale={locale} messages={messages}>
            <AuthProvider>
                <NavbarProvider>
                    <Router>
                    <div className="App">
                        <Navbar setLocale={setLocale}/>
                        <Routes>
                            <Route path="/" element={<Home/>} exact/>
                            <Route path="/register" element={<Register/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/verify/fail" element={<EmailVerificationFail/>} />
                            <Route path="/verify/success" element={<EmailVerificationSuccess/>}/>

                            <Route path="/profile" element={<Profile/>}></Route>
                            <Route path="/projects" element={<Projects/>}></Route>
                            <Route path="/project/create" element={<ProjectForm/>}></Route>
                            <Route path="/project/detail/:id" element={<ProjectDetail/>}></Route>
                            <Route path="/project/requirements/:id" element={<CodeGenarate/>}></Route>
                        </Routes>
                    </div>
                </Router>
                </NavbarProvider>
            </AuthProvider>
        </IntlProvider>
    );
}

export default App;