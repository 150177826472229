import ProjectCard from "./ProjectCard";
import GSidebar from "../../commons/GSidebar";
import Layout from "../../commons/Layout";
import "./Project.css";
import {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import React from 'react';



const Projects = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();



    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_HOST}/v1/project/list`,{
            withCredentials: true,
            //请求头Authorization
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }).then((response) => {
            console.log(response);
            if (response.data.code === 401) {
                navigate('/login');
            }
            if (response.data.code === 200) {
                setProjects(response.data.data);
            }
            //增加项目按钮
            setProjects((projects) => {
                return [
                    ...projects,
                    {
                        id: 0,
                        name: 'New a project',
                        description: 'Create a new project',
                        image_url: 'https://via.placeholder.com/300x200?text=New'
                    }
                ];
            });
        }
        ).catch((error) => {
            console.error(error);
        });
    }
    , []);

  const sidebar = <GSidebar />;
  const Content = ({ projects }) => {
    return (
        <div className="content">
          {projects.map((project) => (
              <ProjectCard key={project.id} project={project}/>
          ))}
        </div>
    );
  };
  const content = <Content projects={projects}/>;

  return (
    <Layout sidebar={sidebar} content={content} />
  );
};

export default Projects;