import React from 'react';
import featureImage from '../images/img.png';

const GStarInfoSection = () => {
    return (
        <div style={{ height: '800px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ display: 'flex', width: '80%', height: '100%', alignItems: 'center' }}>
                {/* 功能说明部分 */}
                <div style={{ flex: 1 }}>
                    <h2>What is GStar?</h2>
                    <ul>
                        <li>功能说明1</li>
                        <li>功能说明2</li>
                        <li>功能说明3</li>
                    </ul>
                </div>
                {/* 功能图片部分 */}
                <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                    <img src={featureImage} alt="GStar功能" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
            </div>
        </div>
    );
};

export default GStarInfoSection;
