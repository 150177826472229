import React, { createContext, useState } from 'react';
import { NavbarContext } from './NavbarContext';

export const NavbarProvider = ({ children }) => {
    const [isShow, setShowNavbar] = useState(true);
    const showNavbar = () => {
        setShowNavbar(true);
    };
    const hideNavbar = () => {
        setShowNavbar(false);
    };


    return (
        <NavbarContext.Provider value={{ isShow, showNavbar, hideNavbar}}>
            {children}
        </NavbarContext.Provider>
    );
};