import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";

function Tables() {
  const [tablesContent, setTablesContent] = useState(null);
  const {id} = useParams();

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_HOST}/v1/chat/tables`;
    axios.get(url, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      params: {
        "project_id": id,
      }
    })
    .then(response => setTablesContent(response.data))
    .catch((error) => {
      console.error('Error:', error);
    });
  }, []);

  return (
    <div>
      {tablesContent ? (
        <div dangerouslySetInnerHTML={{__html: tablesContent.data.replace(/\n/g, '<br/>')}}/>
      ) : 'Loading...'}
    </div>
  );
}

export default Tables;