import React from "react";

function MilestonesSchedule({ Content }) {
  return (
    <section>
      <h2>里程碑和计划</h2>
      {Content.map((milestone, index) => (
        <div key={index}>
          <p>里程碑: {milestone.milestone}</p>
          <p>日期: {milestone.date}</p>
        </div>
      ))}
    </section>
  );
}

export default MilestonesSchedule;