import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import './UserHeader.css';

const menu = (
  <Menu>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="#/action-1">
        Action 1
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="#/action-2">
        Action 2
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="#/action-3">
        Action 3
      </a>
    </Menu.Item>
    <Menu.Item>
      <a target="_blank" rel="noopener noreferrer" href="#/logout">
        Logout
      </a>
    </Menu.Item>
  </Menu>
);

function UserHeader() {
    return (
        <div className="header">
            <img src="https://via.placeholder.com/50x50?text=logo" alt="Logo" className="logo" />
            <div className="navbar-links">
                {/*<a href="/home">Home</a>*/}
                {/*<a href="/projects">Projects</a>*/}
            </div>
            <div className="navbar-user-info">
                <Avatar icon={<UserOutlined />} />
                <Dropdown overlay={menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        User Actions <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        </div>
    );
}

export default UserHeader;