import React from "react";

function Objectives({ Content }) {
  return (
    <section>
      <h2>目标</h2>
      {Content.map((objective, index) => (
        <div key={index}>
          <p>目标: {objective.objective}</p>
          <p>预期成果: {objective.expectedOutcome}</p>
        </div>
      ))}
    </section>
  );
}

export default Objectives;