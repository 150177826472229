import axios from "axios";

const SendMessage = async (inputValue,chatId,preCallback,callback) => {
    if (!inputValue) {
        console.warn('Message cannot be empty');
        return;
    }
    if (!chatId) {
        console.warn('Chat ID is required');
        return;
    }
    if (preCallback) {
        preCallback();
    }

    const url = `${process.env.REACT_APP_API_HOST}/v1/chat`;
    try {
        const response = await axios.post(url, {
            content: inputValue,
            chat_id: chatId
        }, {
            withCredentials: true,
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        if (response.data.code === 200) {
            console.log('Message sent successfully');
            //将消息加入到chatHistory
            callback(response.data);
        } else {
            console.log('Failed to send message');
        }
    } catch (error) {
        console.error(error);
    }
};

export default SendMessage;