import React from "react";

function Introduction({ Content }) {
  return (
    <section>
      <h2>引言</h2>
      <p>目的: {Content.purpose}</p>
      <p>背景: {Content.background}</p>
      <p>范围: {Content.scope}</p>
    </section>
  );
}

export default Introduction;