import React from 'react';

const EmailVerificationSuccess = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <h1>Email Verification Successful</h1>
            <p>Your email has been successfully verified. Thank you!</p>
            <p>Click <a href="/profile">here</a> to profile</p>
        </div>
    );
}

export default EmailVerificationSuccess;