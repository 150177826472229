import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {useParams} from "react-router-dom";
import { Card } from 'antd';


function Func() {
  const [funcContent, setFuncContent] = useState(null);
  const {id} = useParams();

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_HOST}/v1/chat/func`;
    axios.get(url, {
      withCredentials: true,
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
      params: {
        "project_id": id,
      }
    })
    .then(response => {
        console.log(response.data.data);
        let jsonStart = response.data.data.indexOf('[');
        let jsonEnd = response.data.data.lastIndexOf(']') + 2; // 加2是为了包括字符串末尾的"]"
        let jsonString = response.data.data.substring(jsonStart, jsonEnd);
        console.log(jsonString);
        if (jsonString) {
            try {
                const json = JSON.parse(jsonString);
                console.log(json);
                setFuncContent(json);
            } catch (e) {
                console.error('Error parsing JSON:', e);
            }
        }

    })
    .catch((error) => {
      console.error('Error:', error);
    });
  }, []);

    return (
        <div>
            {funcContent ? funcContent.map((item) => {
                return (
                    <Card title={item.title} style={{ marginBottom: '20px' }}>
                        <p><strong>描述：</strong> {item.description}</p>
                        <p><strong>执行顺序：</strong> {item.executionOrder}</p>
                        <p><strong>输入：</strong> {item.input}</p>
                        <p><strong>输出：</strong> {item.output}</p>
                        <p><strong>模型：</strong> {item.model}</p>
                        <p><strong>PRD描述：</strong> {item.prdDescription}</p>
                    </Card>
                );
            }) : '加载中...'}
        </div>
    );
}

export default Func;