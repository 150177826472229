import React, {useEffect, useState} from "react";
import {Button, Card, Input, List} from "antd";
import './requirementsOverview.css';

function FunctionalRequirements({ Content, setSelectedReq }) {

    const handleAdd = (req) => {
        // 在这里实现添加功能
        console.log(`添加功能需求到 ${req.name}`);
        console.log(Content.functionalRequirements);
        //循环Content.functionalRequirements里确认id 等于req.id的对象
        for (let i = 0; i < Content.functionalRequirements.length; i++) {
            if (Content.functionalRequirements[i].id === req.id) {
                Content.functionalRequirements[i].requirements.push({
                    id : Content.functionalRequirements[i].requirements.length + 1,
                    title: '新功能',
                    description: '新功能描述'
                });
                //更新Content.functionalRequirements
                setSelectedReq(Content.functionalRequirements[i].requirements[Content.functionalRequirements[i].requirements.length - 1]);
            }
        }
    };

    return (
        <div className="functional-requirements">
            <Card title="功能需求">
                <List
                    itemLayout="horizontal"
                    dataSource={Content.functionalRequirements}
                    renderItem={req => (
                        <List.Item  actions={[
                            <Button type="primary" onClick={() => handleAdd(req)}>
                                添加功能
                            </Button>
                        ]}>
                            <List.Item.Meta
                                title={req.name}
                                description={
                                    <List
                                        size="small"
                                        bordered
                                        dataSource={req.requirements}
                                        renderItem={requirement => (
                                            <List.Item onClick={() => setSelectedReq(requirement)}>
                                                {requirement.title}
                                            </List.Item>
                                        )}
                                    />
                                }
                            />
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
}

// ... 其他代码保持不变


function MiddleSidePreview({ selectedReq }) {
    const [editableTitle, setEditableTitle] = useState('');
    const [editableDescription, setEditableDescription] = useState('');

    useEffect(() => {
        if (selectedReq) {
            setEditableTitle(selectedReq.title);
            setEditableDescription(selectedReq.description);
        }
    }, [selectedReq]);

    const handleTitleChange = (e) => {
        setEditableTitle(e.target.value);
        if (selectedReq) {
            selectedReq.title = e.target.value;
        }
    };

    const handleDescriptionChange = (e) => {
        setEditableDescription(e.target.value);
        if (selectedReq) {
            selectedReq.description = e.target.value;
        }
    };

    return (
        <div className="middle-preview">
            <Card title={<Input value={editableTitle} onChange={handleTitleChange} />} bordered={false}>
                <Input.TextArea value={editableDescription} onChange={handleDescriptionChange} rows={5} />
                <Button type="primary" style={{marginTop: '10px'}}>保存</Button>
            </Card>
        </div>
    );
}

function RightSidePreview({ selectedReq }) {
    return (
        <div className="right-preview">
            <h2>页面效果</h2>
           <iframe srcDoc={selectedReq ? selectedReq.demo : ''} title="right-preview" width="800px" height="600px" />
        </div>
    );
}

function RequirementsOverview({ Content }) {
  const [selectedReq, setSelectedReq] = useState(null);

  return (
      <section style={{display: 'flex', flexDirection: 'row'}}>
          <FunctionalRequirements Content={Content} setSelectedReq={setSelectedReq}/>
          <MiddleSidePreview selectedReq={selectedReq}/>
          <RightSidePreview selectedReq={selectedReq}/>
      </section>
  );
}

export default RequirementsOverview;