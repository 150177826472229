import React from "react";

function UserFlowsDesigns({ Content }) {
  return (
    <section>
      <h2>用户流程和设计</h2>
      {Content.map((flow, index) => (
        <div key={index}>
          <p>用户故事: {flow.userStory}</p>
          {/* 根据需要展示更多细节 */}
        </div>
      ))}
    </section>
  );
}

export default UserFlowsDesigns;