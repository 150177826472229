import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'antd';
import './ProjectCard.css';

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (project.id !== 0) {
      navigate(`/project/detail/${project.id}`);
    } else {
      navigate('/project/create');
    }
  };

  return (
      <Card
          hoverable
          className="project-card"
          style={{ width: 240 }}
          cover={<img alt="project background" src={project.image_url} />}
          onClick={handleClick}
      >
        <Card.Meta title={project.name} description={project.description} />
      </Card>
  );
};

export default ProjectCard;