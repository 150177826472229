import React from "react";

function DataModel({ Content }) {
  return (
    <section>
      <h2>数据模型</h2>
      {Content.dataEntities.map((entity, index) => (
        <div key={index}>
          <p>实体名称: {entity.entityName}</p>
          {entity.attributes.map((attr, idx) => (
            <div key={idx}>
              <p>属性名称: {attr.attributeName}</p>
              <p>描述: {attr.description}</p>
            </div>
          ))}
        </div>
      ))}
    </section>
  );
}

export default DataModel;