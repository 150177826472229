import React, {useContext, useState} from 'react';
import axios from 'axios';
import {FormattedMessage} from "react-intl";
import {AuthContext} from "../provider/AuthContext";
import { useNavigate } from 'react-router-dom';
import {Button, Card, Form, Input} from "antd";
import './Login.css';

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { isLogged, login, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            const { email, password } = values;
            //验证邮箱
            const emailRegex = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
            if (!emailRegex.test(email)) {
                return alert('邮箱格式不正确');
            }
            //验证密码
            if (password.length < 6) {
                return alert('密码长度不能小于6位');
            }
            const response = await axios.post(`${process.env.REACT_APP_API_HOST}/v1/login`, { email, password }, { withCredentials: true });
            if (response.data.code === 200) {
                login();
                localStorage.setItem('token', response.data.data.token);
                //跳转到首页
                navigate('/profile');
            }else {
                alert(response.data.message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="login-container">
            <Card title={<FormattedMessage id="login" />} className="card-style">
                <Form onFinish={handleSubmit}>
                    <Form.Item name="email" rules={[{ required: true, message: '请输入邮箱!' }]}>
                        <Input type="email" placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: '请输入密码!' }]}>
                        <Input type="password" placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            <FormattedMessage id="submit"/>
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
}

export default Login;