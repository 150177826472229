import {Button, Card, Input, Tabs} from "antd";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import SendMessage from "./base";


function Needs() {
    const [requirements, setRequirements] = useState([]);
    const [chatHistory, setChatHistory] = useState([]);
    const {id} = useParams();
    const {TextArea} = Input;
    const textAreaRef = useRef(null);
    const [inputValue, setInputValue] = useState('');
    const navigate = useNavigate();


    useEffect(() => {
        fetchRequirements();
    }, []);

    const fetchChatHistory = async (chat_id, page, pageSize) => {
        const url = `${process.env.REACT_APP_API_HOST}/v1/chat/list`;
        try {
            const response = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                params: {
                    "chat_id": chat_id, "page": page,
                    "page_size": pageSize
                }
            });
            if (response.data.code === 200) {
                setChatHistory(response.data.data);
            } else {
                console.log('Failed to fetch chat history');
            }
        } catch (error) {
            console.error(error);
        }
    };
    const fetchRequirements = async () => {
        const url = `${process.env.REACT_APP_API_HOST}/v1/project/detail`;
        try {
            const response = await axios.get(url, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                params: {
                    "project_id": id
                }
            });
            if (response.data.code === 200) {
                setRequirements(response.data.data);
                fetchChatHistory(response.data.data.user_stage, 1, 100);
            } else {
                console.log('Failed to fetch requirements detail');
            }
        } catch (error) {
            console.error(error);
        }
    };

    if (!requirements || !chatHistory) {
        return <div>Loading...</div>;
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    function sendMessage() {
        SendMessage(inputValue, requirements.user_stage, () => {
            setInputValue('');
            textAreaRef.current.focus();
            setChatHistory([...chatHistory, {
                role: 'user',
                content: inputValue
            }]);
        }, (data) => {
            //将消息加入到chatHistory
            setChatHistory((chatHistory) => {
                return [
                    ...chatHistory,
                    {
                        role: 'assistant',
                        content: data.data.content,
                    }
                ];
            });
        }).then(r => {
            console.log(r);
        });
    }


    const generateProductDocument = async () => {
        const url = `${process.env.REACT_APP_API_HOST}/v1/chat/prd`;
        console.log(localStorage.getItem('token'));
        try {
            const response = await axios.post(url, {
                "project_id": requirements.id
            }, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.data.code === 200) {
                console.log('Product document generated successfully');
                //提示正在生成产品文档
                alert('正在生成产品文档，预计需要30s');
                //30s后跳转到产品文档页面
                setTimeout(() => {
                    navigate('?tabKey=2');
                }, 30000);
            } else {
                console.log('Failed to generate product document');
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <p>{requirements.name}--{requirements.description}</p>

            {chatHistory.length > 0 && (
                <div className="chat-history">
                    <Card title={
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            对话历史
                            <Button type="primary" onClick={generateProductDocument}>
                                生成产品文档
                            </Button>
                        </div>
                    } bordered={false}>
                        {chatHistory.map((chat) => (
                            <p key={chat.id}>
                                <h2>{chat.role} :</h2>
                                <div dangerouslySetInnerHTML={{__html: chat.content.replace(/\n/g, '<br/>')}}/>
                            </p>
                        ))}
                    </Card>
                </div>
            )}

            <div className="chat-input">
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <TextArea
                        ref={textAreaRef}
                        value={inputValue}
                        placeholder="请用文字简短的描述一下需求"
                        onChange={handleInputChange}
                        style={{marginRight: '10px'}}
                    />
                    <Button type="primary" onClick={sendMessage}>Send</Button>
                </div>
            </div>
        </div>
    );
}

export default Needs;